<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<div
					:class="!showFilters ? 'btn-primary' : 'btn-secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters;">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</div>
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
				<b-button
					:disabled="exportLoading"
					class="float-right"
					variant="success"
					@click="exportReport">
					<i :class="!exportLoading ? 'fas fa-file-excel' : 'fas fa-spinner fa-spin'" /> {{ translate('export') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									v-for="dateRangeInfo in dateRanges"
									:key="dateRangeInfo"
									:value="dateRangeInfo">
									{{ translate(dateRangeInfo) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="startDate"
								class="label">{{ translate('start_date') }}</label>
							<v-date-picker
								id="startDate"
								v-model="startDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="startDate"
								show-caps />
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="endDate"
								class="label">{{ translate('end_date') }}</label>
							<v-date-picker
								id="endate"
								v-model="endDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="endDate"
								show-caps />
						</div>
					</div>
					<div
						v-if="dateRange !== dateRanges.custom"
						class="col-sm">
						<div class="form-group">
							<label
								for="countryName"
								class="label">{{ translate('country') }}</label>

							<select
								id="countryName"
								v-model="filters.country"
								name="country"
								class="form-control">
								<template>
									<option :value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="country in countries"
										:key="country.attributes.code"
										:value="country.attributes.code">
										{{ translate(country.attributes.code.toLowerCase()) }}
									</option>
								</template>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange !== dateRanges.custom"
						class="col-sm">
						<div class="form-group">
							<label
								for="method"
								class="label">{{ translate('payment_method') }}</label>

							<select
								id="payment_method"
								v-model="filters.payment_method"
								name="payment_method"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="method in methods"
									:key="method.attributes.method_code_name"
									:value="method.attributes.method_code_name">
									{{ translate(`sales_${method.attributes.method_code_name.toLowerCase()}`) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange !== dateRanges.custom"
						class="col-md-4">
						<div
							class="form-group">
							<label
								for="orderId"
								class="label">{{ translate('order_id') }}</label>
							<input
								id="orderId"
								v-model="filters.orderId"
								:placeholder="translate('type_order')"
								type="text"
								name="orderId"
								class="form-control">
						</div>
					</div>
				</div>
				<div class="row">
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-md-4">
						<div class="form-group">
							<label
								for="countryName"
								class="label">{{ translate('country') }}</label>

							<select
								id="countryName"
								v-model="filters.country"
								name="country"
								class="form-control">
								<template>
									<option :value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="country in countries"
										:key="country.attributes.code"
										:value="country.attributes.code">
										{{ translate(country.attributes.code.toLowerCase()) }}
									</option>
								</template>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-md-4">
						<div class="form-group">
							<label
								for="method"
								class="label">{{ translate('method') }}</label>

							<select
								id="payment_method"
								v-model="filters.payment_method"
								name="payment_method"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="method in methods"
									:key="method.attributes.method_code_name"
									:value="method.attributes.method_code_name">
									{{ translate(`sales_${method.attributes.method_code_name.toLowerCase()}`) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-md-4">
						<div
							class="form-group">
							<label
								for="orderId"
								class="label">{{ translate('order_id') }}</label>
							<input
								id="orderId"
								v-model="filters.orderId"
								:placeholder="translate('type_order')"
								type="text"
								name="orderId"
								class="form-control">
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<b-row>
			<b-col class="col-12">
				<div class="animated fadeIn">
					<div class="table-responsive">
						<table class="table table-hover table-striped text-nowrap">
							<thead>
								<tr>
									<th class="border-top-0">
										#
									</th>
									<th
										class="border-top-0 text-left">
										{{ translate('order_id') }}
									</th>
									<th
										class="border-top-0 text-left">
										{{ translate('status') }}
									</th>
									<th class="border-top-0 text-center">
										{{ translate('country') }}
									</th>
									<th class="border-top-0 text-left">
										{{ translate('payment_method') }}
									</th>
									<th
										class="border-top-0 text-right">
										{{ translate('subtotal') }}
									</th>
									<th
										class="border-top-0 text-right">
										{{ translate('discount') }}
									</th>
									<th
										class="border-top-0 text-right">
										{{ translate('shipping') }}
									</th>
									<th
										class="border-top-0 text-right">
										{{ translate('shipping_tax') }}
									</th>
									<th
										class="border-top-0 text-right">
										{{ translate('us_taxes') }}
									</th>
									<th
										class="border-top-0 text-right">
										{{ translate('duties') }} ({{ translate('non_us') }})
									</th>
									<th
										class="border-top-0 text-right">
										{{ translate('total') }}
									</th>
									<!--<th class="border-top-0 text-center">-->
									<!--	{{ translate('agency_earning_percent') }}-->
									<!--</th>-->
									<!--<th class="border-top-0 text-right">-->
									<!--	{{ translate('agency_earning_amount') }}-->
									<!--</th>-->
									<th
										class="border-top-0 text-left">
										{{ translate('order_paid_date') }}
									</th>
								</tr>
							</thead>
							<tbody v-if="!loading && hasData">
								<tr
									v-for="(item, index) in data"
									:key="index">
									<td class="align-middle">
										{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
									</td>
									<td class="text-left">
										{{ item.id }}
										<i
											v-if="item.attributes.is_fraud"
											v-b-tooltip.hover
											:title="translate('fraudulent_order')"
											class="fa fa-info-circle"
											style="margin-left:5px; margin-top:2px; color:#f86c6b" />
										<i
											v-if="item.attributes.is_invisible"
											v-b-tooltip.hover
											:title="translate('invisible_order')"
											class="fa fa-eye-slash"
											style="margin-left:5px; margin-top:2px; color:gray" />
									</td>
									<td class="text-left">
										{{ translate(item.attributes.status) }}
									</td>
									<td class="text-center">
										<img
											v-b-tooltip.hover
											class="ml-3"
											:title="translate(item.attributes.country_code.toLowerCase())"
											:src="require(`@/assets/images/common/countries/select/${item.attributes.country_code.toUpperCase()}.png`)"
											:alt="translate(item.attributes.country_code.toLowerCase())">
									</td>
									<td class="text-left">
										{{ translate(`sales_${item.attributes.payment_method}`) }}
									</td>
									<td class="text-right">
										{{ item.attributes.subtotal }}
									</td>
									<td class="text-right">
										{{ item.attributes.discount }}
									</td>
									<td class="text-right">
										{{ item.attributes.shipping }}
									</td>
									<td class="text-right">
										{{ item.attributes.shipping_tax }}
									</td>
									<td class="text-right">
										{{ item.attributes.tax_us }}
									</td>
									<td class="text-right">
										{{ item.attributes.tax_no_us }}
									</td>
									<td class="text-right">
										{{ item.attributes.total }}
									</td>
									<!--<td class="text-center">-->
									<!--	{{ item.attributes.earning_amount }}%-->
									<!--</td>-->
									<!--<td class="text-right">-->
									<!--	{{ item.attributes.amount_received }}-->
									<!--</td>-->
									<td class="text-left">
										{{ $moment(item.attributes.paid_at.date).format(dateFormat) }}
									</td>
								</tr>
								<tr class="text-right">
									<td colspan="5">
										<b />
									</td>
									<td class="text-right">
										<b>{{ totals.subtotal }}</b>
									</td>
									<td class="text-right">
										<b>{{ totals.discount }}</b>
									</td>
									<td class="text-right">
										<b>{{ totals.shipping }}</b>
									</td>
									<td class="text-right">
										<b>{{ totals.shipping_tax }}</b>
									</td>
									<td class="text-right">
										<b>{{ totals.tax_us }}</b>
									</td>
									<td class="text-right">
										<b>{{ totals.tax_no_us }}</b>
									</td>
									<td class="text-right">
										<b>{{ totals.total }}</b>
									</td>
									<!--<td />-->
									<!--<td class="text-right">-->
									<!--	<b>{{ totals.amount_received }}</b>-->
									<!--</td>-->
									<td />
								</tr>
							</tbody>
						</table>
					</div>
					<is-loading
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')"
						:loading="loading"
						:has-data="hasData" />
					<b-pagination
						v-if="pagination.total_pages > 1"
						v-model="pagination.current_page"
						:total-rows="pagination.total"
						:per-page="pagination.per_page"
						align="center"
						@change="getDataFiltered" />
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>

import {
	Report, SalesPaymentMethods as methodsMessages, Countries, Purchase, Grids,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import {
	PERIOD_FILTERS as dateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
	DATE_START_FILTER as dateStartFilter,
	DATE_END_FILTER as dateEndFilter,
	allTime,
	custom, YMDHMS_FORMAT, MDY_FORMAT,
} from '@/settings/Dates';
import DataFilter from '@/components/DataFilter';
import Sales from '@/util/Report';
import Country from '@/util/Country';
import PaymentMethods from '@/util/PaymentMethods';
import { PAGINATION } from '@/settings/RequestReply';

const countries = new Country();
const methods = new PaymentMethods();

export default {
	name: 'Sales',
	messages: [Report, methodsMessages, Countries, Purchase, Grids],
	components: { DataFilter },
	mixins: [FiltersParams],
	data() {
		return {
			dateRanges,
			allTime,
			custom,
			calendarConfig,
			exportLoading: false,
			sales: new Sales(),
			alert: new this.$Alert(),
			dateRange: dateRanges.today,
			rangeStart: '',
			rangeEnd: '',
			countries: [],
			methods: [],
			dateFormat: YMDHMS_FORMAT,
			mdyFormat: MDY_FORMAT,
		};
	},
	computed: {
		query() {
			return this.$route.query;
		},
		datePeriod() {
			return this.getDatePeriod();
		},
		countryCode() {
			return this.$user.details().country.iso_code_2;
		},
		loading() {
			return !!this.sales.data.loading;
		},
		errors() {
			return this.sales.data.errors;
		},
		pagination() {
			return this.sales.data.pagination;
		},
		data() {
			try {
				const { data } = this.sales.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		totals() {
			try {
				const { totals } = this.sales.data.response.data.meta;
				return totals;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		countries.getCountries({ also_pickup_stores: true }).then((response) => {
			this.countries = response;
		});

		methods.getAllPaymentMethods().then((response) => {
			this.methods = response;
		});

		this.sales.getAgencySales().then(() => {
			const { start, end } = this.sales.data.response.data.meta.period_dates;
			this.rangeStart = this.$moment(start.date).format(this.mdyFormat);
			this.rangeEnd = this.$moment(end.date).format(this.mdyFormat);
		});

		const { query } = this.$route;
		const dateKeys = Object.keys(query);
		if (typeof this.dateRanges[query.dateRange] !== 'undefined') {
			this.dateRange = query.dateRange;
		} else if (typeof query.dateRange !== 'undefined' && !Object.values(this.dateRanges).includes(query.dateRange)) {
			this.dateRange = this.dateRanges.today;
			query.dateRange = this.dateRanges.today;
		} else if (dateKeys.includes(dateStartFilter) || dateKeys.includes(dateEndFilter)) {
			this.dateRange = this.dateRanges.custom;
		} else if (typeof query.dateRange === 'undefined') {
			this.dateRange = this.dateRanges.today;
		}
		return null;
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.$router.push({ name: this.$route.name, query: options });
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
		exportReport() {
			const options = { ...this.query };
			const totals = new Sales();
			this.exportLoading = true;

			totals.downloadAgencySalesReport(options).then(() => {
				const { response } = totals.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.datePeriod} - ${this.translate('agency_sales_report')}.xlsx`);
				this.$el.appendChild(link);
				link.click();
				this.exportLoading = false;
			}).catch(() => {
				this.exportLoading = false;
			});
		},
	},
};
</script>
